<!-- 评论列表 -->
<template>
  <div class="remarklist">
    <div class="list"  v-for='i,n in data'>
      <!-- {{i.IsAnonymous==1?"匿名用户":"用户："}}{{?"":i.ContactPhone | formatPhoneHide}} -->
      <!-- <h6 class="h6">{{i.CustomerName?i.CustomerName:'' | formatPhoneHide(1)}}<span>{{i.CreateDate.split('T')[0]}}</span></h6> -->
      <h6 class="h6">{{i.IsAnonymous==1?"匿名用户":i.CustomerName | formatPhoneHide(1)}} <span>{{i.CreateDate.split('T')[0]}}</span></h6>
      <!-- <p class="times"></p> -->
      <p class="msg">{{i.Content}}</p>
      <div class="upImg">
        <el-image
          v-for='is,ns in i.ImageUrl' :key='ns'
          :src="is | imgsrc(that)"
          :preview-src-list="i.ImageUrl | imgarr(that)">
        </el-image>
        <!-- <img @click='previewDetailimg(item.ImageUrl)' :src="item.ImageUrl | imgsrc(that)"  v-for='item,index in i.imglist' :key='index'> -->
      </div>
      <div class="star">
        <div class="starBox" :class='["one","two","three","four","five"][(i.Score/2)-1]'>
          <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
          <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
          <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
          <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
          <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
        </div>
        {{['一','二','三','四','五'][(i.Score/2)-1]}}星{{['好评','中评','差评'][i.ScoreType - 1]}}
      </div>
      <!-- <p class="time">{{i.time}}</p> -->
      <h6 v-if='i.ReplyDate' class="h7 h8">{{i.ReplyDate.split('T')[0]}}<span>平台回复</span></h6>
      <p v-if='i.ReplyContent' class="msg msg1">{{i.ReplyContent}}</p>
      <div class="remarkafter" v-if='i.childlist' v-for='item,index in i.childlist' :key='index'>
        <h6  class="h7">{{item.CreateDate.split('T')[0]}}<span>追评</span></h6>
        <p class="msg">{{item.Content}}</p>
        <div class="upImg">
          <el-image
            v-for='is,ns in item.ImageUrl' :key='ns'
            :src="is | imgsrc(that)"
            :preview-src-list="item.ImageUrl | imgarr(that)">
          </el-image>
          <!-- <img @click='previewDetailimg(item.ImageUrl)' :src="item.ImageUrl | imgsrc(that)"  v-for='item,index in i.imglist' :key='index'> -->
        </div>
        <h6 v-if='item.ReplyDate' class="h7 h8">{{item.ReplyDate.split('T')[0]}}<span>平台回复</span></h6>
        <p v-if='item.ReplyContent' class="msg msg1">{{item.ReplyContent}}</p>
      </div>
    </div>
    <button @click='moreList' v-show='total>data.length'>查看更多</button>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'remarklist',
  mixins: [mixin],//混入
  data(){
    return {
      that:this,
      dialogImageUrl: '',
      dialogVisible: false,
    }
  },
  props: {
    data:Array,
    total:Number
  },
  methods:{
    moreList(){
      // 加载更多数据
      this.$emit('exportMore')
    },
    previewDetailimg(v){
      // 预览详情图片
      this.dialogImageUrl = this._src + v;
      this.dialogVisible = true;
    },
  },
  mounted(){
  },
  filters:{
    imgsrc(v,that){
      return that._src + v
    },
    imgarr(v,that){
      var _imgarr=[];
      for(let i in v){
        _imgarr.push(that._src + v[i])
      }
      return _imgarr
    }
  }
}
</script>
<style  lang="scss" scoped>
.remarklist {
  padding:0 20px;
  .list {
    border-bottom:1px solid #ededed;margin-bottom:15px;padding-bottom:20px;
    .h6 {
      font-size: 14px;height: 28px;line-height: 28px;
      span {
        font-size: 12px;font-weight: normal;color:#999;margin-left:20px;
      }
    }
    .h7 {
      font-size: 14px;font-weight: normal;color:#999;
      span {
        margin-left:10px;color:#999;
      }
    }
    .h8 {
      margin-top:15px;color:#fe7c80;
      span {
        color:#fe7c80;
      }
    }
    .times {
      font-size: 12px;font-weight: normal;color:#999;
    }
    .msg {
      color:#414141;font-size: 14px;margin-top:5px;
    }
    .msg1 {
      color:#fe7c80;
    }
    .upImg {
      margin-top:12px;zoom:1;
      img {
        width: 40px;height: 40px;display: block;float:left;cursor: pointer;border:1px solid #ccc;margin-right: 20px;
      }
      &:after {
        content:'';display: block;clear:both;
      }
     /deep/.el-image {
        float:left;display: block;margin-right:10px;min-width: 40px;min-height: 40px;max-width: 40px;max-height: 40px;border:1px solid #f2f2f2;
        .el-icon-circle-close:before {
          font-size: 40px;
        }
      }
    }
    .star {
      margin-top:12px;color:#242426;font-size: 14px;margin-bottom:10px;
      .starBox {
        float:left;margin-right: 5px;
        svg {
          fill: #ccc;width: 18px;height: 18px;vertical-align: -4px;margin-right: 7px;
        }
      }
      .one {
        svg:nth-child(-n+1) {
          fill: #fb3f80;
        }
      }
      .two {
        svg:nth-child(-n+2) {
          fill: #fb3f80;
        }
      }
      .three {
        svg:nth-child(-n+3) {
          fill: #fb3f80;
        }
      }
      .four {
        svg:nth-child(-n+4) {
          fill: #fb3f80;
        }
      }
      .five {
        svg:nth-child(-n+5) {
          fill: #fb3f80;
        }
      }
    }
    .time {
      margin-top:8px;color:#989898;font-size: 14px;margin-bottom:10px;
    }
    .remarkafter {
      margin-top:20px;
    }
  }
  button {
    background: none;display: block;width: 100%;height: 40px;line-height: 40px;margin-top:40px;margin-bottom:20px;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
