<!-- 产品详情-图片展示 -->
<template>
  <div class="carousebox">
    <carouselFade height='540px' :datas='carousels' :auto='false' :carouselImgBtm='carouselImgBtm' :type='1'
      :hoverSlt='false'
      :menu="{
        width:'450px',
        top:'190px',
        left:'30px',
        right:'30px'
      }"
      :menu1='{
      width:"1200px",
      top:"260px",
      right:"0px",
      left:null,
      show:false
      }'>
    </carouselFade>
    <!-- <el-carousel :autoplay="false" arrow="always" :height='height'  :arrow='"hover"' @change='changes' ref='carousel' indicator-position='none'>
      <el-carousel-item v-for="item,index in carousels" :key="index">
        <img v-if='expimg(item)' :src='item.ImagePath | _src(that)'>
        <video v-if='exvideo(item)' autoplay="autoplay" muted  :src="item.ImagePath | _src(that)" controls="controls" ref='video'>
        您的浏览器不支持 video 标签。
        </video>
      </el-carousel-item>
    </el-carousel>
    <div class="indicator">
      <el-carousel :autoplay="false" arrow="always" height='108px'  :arrow='"hover"' indicator-position='none'>
        <el-carousel-item v-for="item,index in carouselImgBtm" :key="index">
          <div class="showbox">
            <div :class='{active:actives==ins}' class="box" v-for='its,ins in item.arr' :key='ins' @mousemove='setActive(ins)'>
              <img v-if='expimg(its)' :src="its.ImagePath | _src(that)">
              <video v-if='exvideo(its)' :src="its.ImagePath | _src(that)">
              您的浏览器不支持 video 标签。
              </video>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
  </div>
</template>
<script>
import carouselFade from '@/components/carouselFade/carouselFade.vue';//轮播组件
export default {
  name: 'preshow',
  props: {
    height: String,
    carousels:Array,
    carouselImgBtm:Array
  },
  components:{
    carouselFade
  },
  data(){
    return {
      actives:0,
      that:this
    }
  },
  methods:{
    setActive(index){
      // 切换幻灯片
      this.actives = index
      this.$refs.carousel.setActiveItem(index)
      // console.log(this.$refs.video)
      // 关闭组件弹窗播放
      for(let i in this.$refs.video){
        // 暂停组件内其他视频
        this.$refs.video[i].pause()
      }
    },
    changes(index){
      // 切换幻灯片
      this.actives = index
      // 关闭组件弹窗播放
      for(let i in this.$refs.video){
        // 暂停组件内其他视频
        this.$refs.video[i].pause()
      }
    },
    expimg(item){
      // console.log(item)
      var _ex = /\.jpg|\.png|\.jpeg/ig;
      // console.log(_ex.test(item.ImagePath))
      return _ex.test(item.ImagePath)
    },
    exvideo(item){
      var _ex = /\.mp4/ig;
      // console.log(_ex.test(item.ImagePath))
      return _ex.test(item.ImagePath)
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // // console.log(that.$refs.video)
      // console.log(this.carouselImgBtm)
    })
  },
  filters:{
    _src(v,that){
      return that._src + v
    },
  }
}
</script>
<style  lang="scss" scoped>
.el-carousel__item {
  overflow: hidden;border-radius: 5px;display: flex;justify-content: center;align-items: center;
  img {
    width: 450px;height: 450px;
  }
  video {
    width: 450px;height: 450px;
  }
}
.indicator {
    height: 78px; margin-top:12px;margin-bottom:45px;
  .showbox {
    width: 100%;
    .box {
      width: 78px;height: 78px;border-radius: 5px;overflow: hidden;cursor: pointer;margin-right:12px;float:left;
      img {
        display: block;width: 78px;height: 78px;
      }
      video {
        width: 78px;height: 78px;
      }
      &:last-child {
        margin-right:0px;
      }
    }
    .active {
      width: 74px;height: 74px;border:2px solid #fb546b;
    }
  }
}
</style>
