<!-- 评论列表标签页 -->
<template>
  <div class="remarktabs">
    <el-tabs v-model="activeName" v-if='active == "second"'>
      <el-tab-pane :label="'全部('+datas.total+')'" name="all" >
          <remarklist @exportMore='exportMore' :total='datas.total' :data='datas.ProductEvaluateList'></remarklist>
      </el-tab-pane>
      <el-tab-pane :label="'好评('+datas.goodnum+')'" name="good" >
          <remarklist @exportMore='exportMore'  :total='datas.goodnum' :data='datas.ProductEvaluateList'></remarklist>
      </el-tab-pane>
      <el-tab-pane :label="'中评('+datas.midnum+')'" name="mid" >
          <remarklist @exportMore='exportMore' :total='datas.midnum' :data='datas.ProductEvaluateList'></remarklist>
      </el-tab-pane>
      <el-tab-pane :label="'差评('+datas.upnum+')'" name="low">
          <remarklist @exportMore='exportMore' :total='datas.upnum' :data='datas.ProductEvaluateList'></remarklist>
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="activeName1" v-if='active == "third"'>
      <el-tab-pane :label="'全部咨询('+(parseInt(askType[0].num)+parseInt(askType[1].num)+parseInt(askType[2].num)+parseInt(askType[3].num))+')'" name="askAll" >
          <asklist  @exportMore1='exportMore1'  :total='(parseInt(askType[0].num)+parseInt(askType[1].num)+parseInt(askType[2].num)+parseInt(askType[3].num))' :data='askData.list'></asklist>
      </el-tab-pane>
      <el-tab-pane :label="askType[0].ConsultingTypeName+'('+askType[0].num+')'" name="commodity" >
          <asklist @exportMore1='exportMore1' :total='askType[0].num' :data='askData.list'></asklist>
      </el-tab-pane>
      <el-tab-pane :label="askType[1].ConsultingTypeName+'('+askType[1].num+')'" name="repertory" >
          <asklist @exportMore1='exportMore1' :total='askType[1].num'  :data='askData.list'></asklist>
      </el-tab-pane>
      <el-tab-pane :label="askType[2].ConsultingTypeName+'('+askType[2].num+')'" name="payment">
          <asklist @exportMore1='exportMore1' :total='askType[2].num'  :data='askData.list'></asklist>
      </el-tab-pane>
      <el-tab-pane :label="askType[3].ConsultingTypeName+'('+askType[3].num+')'" name="bill">
          <asklist @exportMore1='exportMore1' :total='askType[3].num'  :data='askData.list'></asklist>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import remarklist from '@/components/remarklist/remarklist.vue';//产品品论摘要
import asklist from '@/components/asklist/asklist.vue';//产品品论摘要
export default {
  name: 'remarktabs',
  components:{
    remarklist,
    asklist
  },
  data(){
    return {
      activeName:"all",
      activeName1:"askAll",
      num:10,//评论默认请求数量
      num1:10,//咨询默认请求数量
      askTypeId:'',
    }
  },
  props: {
    datas: Object,
    active:String,
    askData:Object,//咨询数据
    askType:Array,//咨询类型
    // activeParent:String,
  },
  methods:{
    exportMore(){
      this.num+=20
      // 评论加载更多
      switch(this.activeName){
        case 'all':
          // 全部评论
          this.$emit('exportUp',{typ:0,num:this.num})
          break;
        case 'good':
          // 好评
          this.$emit('exportUp',{typ:1,num:this.num})
          break;
        case 'mid':
          // 中评
          this.$emit('exportUp',{typ:2,num:this.num})
          break;
        case 'low':
          // 差评
          this.$emit('exportUp',{typ:3,num:this.num})
          break;
      }
    },
    exportMore1(){
      // 咨询加载更多
      this.num1+=20
      switch(this.activeName1){
        case 'askAll':
          // 全部评论
          this.askTypeId = ''
          this.$emit('exportUp1',{typ:'',num:this.num1})
          break;
        case 'commodity':
          // 好评
          this.askTypeId = this.askType[0].Id
          this.$emit('exportUp1',{typ:this.askType[0].Id,num:this.num1})
          break;
        case 'repertory':
          // 中评
          this.askTypeId = this.askType[1].Id
          this.$emit('exportUp1',{typ:this.askType[1].Id,num:this.num1})
          break;
        case 'payment':
          // 差评
          this.askTypeId = this.askType[2].Id
          this.$emit('exportUp1',{typ:this.askType[2].Id,num:this.num1})
          break;
        case 'bill':
          // 差评
          this.askTypeId = this.askType[3].Id
          this.$emit('exportUp1',{typ:this.askType[3].Id,num:this.num1})
          break;
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(datas,askData)
    })
  },
  watch:{
    activeName(n,o){
      this.num = 20;
      switch(n) {
        case 'all':
          // 全部评论
          this.$emit('exportUp',{typ:0,num:this.num})
          break;
        case 'good':
          // 好评
          this.$emit('exportUp',{typ:1,num:this.num})
          break;
        case 'mid':
          // 中评
          this.$emit('exportUp',{typ:2,num:this.num})
          break;
        case 'low':
          // 差评
          this.$emit('exportUp',{typ:3,num:this.num})
          break;
      }
    },
    activeName1(n,o){
      this.num1 = 20;
      switch(n) {
        case 'askAll':
          this.askTypeId = ''
          this.$emit('exportUp1',{typ:'',num:this.num1})
          break;
        case 'commodity':
          this.askTypeId = this.askType[0].Id
          this.$emit('exportUp1',{typ:this.askType[0].Id,num:this.num1})
          break;
        case 'repertory':
        this.askTypeId = this.askType[1].Id
          this.$emit('exportUp1',{typ:this.askType[1].Id,num:this.num1})
          break;
        case 'payment':
        this.askTypeId = this.askType[2].Id
          this.$emit('exportUp1',{typ:this.askType[2].Id,num:this.num1})
          break;
        case 'bill':
        this.askTypeId = this.askType[3].Id
          this.$emit('exportUp1',{typ:this.askType[3].Id,num:this.num1})
          break;
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.remarktabs {
  width: 100%;
  /deep/.el-tabs {
    background: #fff;
    .el-tabs__nav-wrap::after{
      background: #ffcece !important;top:auto !important;bottom:1px;height: 1px !important;
    }
    .el-tabs__item {
      min-width: 135px !important;text-align: center;
    }
    .is-active {
      color:#fe7c80;
    }
    .el-tabs__item:hover {
      color:#fe7c80;
    }
  }
  .outHtmlt {
    padding:10px 50px;
  }
  .remark {
    padding:10px 50px;
  }
}
</style>
