<!-- 相关分类 -->
<template>
  <div class="relateclass">
    <h6>相关分类</h6>
    <el-collapse :value='num'>
	  <el-collapse-item   :style='{background:randomColor()}'  :name="n+1" v-for='i,n in data' :key='n'>
      <template slot="title">
        <p :title='i.TypeName'>{{i.TypeName}}</p>
      </template>
	    <div v-for='is,ns in i.childList' :key='ns' :title='is.AttachTypeName' @click='links(is)'>{{is.AttachTypeName}}</div>
	  </el-collapse-item>
	</el-collapse>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'relateclass',
  mixins: [mixin],//混入
  data(){
  	return {
      num:[1,2,3],//固定前2个打开
  		// num:[Math.floor(Math.random()*this.data.length),Math.floor(Math.random()*this.data.length),Math.floor(Math.random()*this.data.length),Math.floor(Math.random()*this.data.length)]//随机打开
  	}
  },
  props: {
    data: Array
  },
  methods:{
    links(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    }
  }
}
</script>
<style  lang="scss" scoped>
.relateclass {
  background: #fff;padding-bottom:10px;
	h6 {
    position: relative;margin:0 14px;padding-top:24px;height: 24px;line-height: 24px;font-size: 14px;padding-left:13px;color:#252525;margin-bottom:10px;
    &:after{
      content: '';display: block;position: absolute;width: 8px;height: 24px;background: #fe7c80;border-radius: 5px;top:24px;left:0px;
    }
  }
  /deep/.el-collapse {
  	width: 254px;margin:0 auto;border:0;
    .el-collapse-item {
      margin-bottom:10px;border-radius: 5px;
      .el-collapse-item__header  {
        background: none;border-bottom:1px dashed  #fff;text-align: center;color:#252525;font-weight:bold;
        &:hover {
          opacity: 0.8;
        }
        p {
          width: 100%;padding-left:20px;font-size: 15px;display: inline-block;white-space: nowrap; overflow: hidden;text-overflow:ellipsis;width: 100%;
        }
        .el-icon-arrow-right:before {
          color:#fff;font-weight: bold;
        }
      }
      .el-collapse-item__wrap {
        background: none;border-bottom:0;text-align: center;
        .el-collapse-item__content {
          padding:15px;
          div {
            font-size: 14px;color:#979897;height: 34px;line-height: 34px;cursor: pointer;display: inline-block;white-space: nowrap; overflow: hidden;text-overflow:ellipsis;width: 100%;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
</style>
