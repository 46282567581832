var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousebox"},[_c('carouselFade',{attrs:{"height":"540px","datas":_vm.carousels,"auto":false,"carouselImgBtm":_vm.carouselImgBtm,"type":1,"hoverSlt":false,"menu":{
      width:'450px',
      top:'190px',
      left:'30px',
      right:'30px'
    },"menu1":{
    width:"1200px",
    top:"260px",
    right:"0px",
    left:null,
    show:false
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }