<!-- 产品精选 -->
<template>
  <div class="prechoice">
    <h6 class="h6">
      <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
        商品精选
      <svg class="icon"><use xlink:href="#iconwujiaoxing"></use></svg>
    </h6>
    <div class="prelist">
      <div class="preitem" v-for='i,n in data' :key='n' @click='links(i)'>
        <div class="box">
          <img :src="i.ImagePath | urlMake(that)">
        </div>
        <h6 :title='i.TypeName'>{{i.TypeName}}</h6>
        <p class="price"><span>&yen;</span>{{i.Price | formatPrice}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'prechoice',
  mixins: [mixin],//混入
  data(){
    return {
      that:this,
    }
  },
  props: {
    data: Array
  },
  methods:{
    links(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    }
  },
  filters:{
    urlMake(v,that){
      return that._src + v
    }
  }
}
</script>
<style  lang="scss" scoped>
.prechoice {
  width: 1200px;margin:0 auto;padding-top:30px;padding-bottom:20px;
  .h6 {
    width: 305px;margin:0 auto;position: relative;font-size: 14px;text-align: center;height: 30px;line-height: 30px;color:#1b2a3b;
    &:before,&:after {
      content: '';display: block;width: 89px;height: 1px;background: #fb546b;position: absolute;top:15px;
    }
    &:before {
      left:0px;
    }
    &:after {
      right:0px;
    }
    svg {
      fill: #fb546b;width: 16px;height: 16px;vertical-align: -3px;margin:0 5px;
    }
  }
  .prelist {
    display: flex;margin-top:10px;justify-content: flex-start;overflow: hidden;padding:10px;margin-left:-10px;
    .preitem {
      display: block;width: 162px;cursor: pointer;margin-right: 10px;
      &:hover {
        // opacity: 0.8;
        box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
      }
      .box {
        width: 162px;height: 162px;border-radius: 10px;overflow: hidden;display: flex;justify-content: center;align-items: center;
        img {
          display: block;width: 150px;height: 150px;
        }
      }
      h6 {
        color:#565455;font-size: 14px;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;padding-left:10px;
      }
      .price {
        color:#ef4254;font-size: 14px;padding-left:10px;padding-bottom:10px;
        span {
          margin-right:3px;
        }
      }
    }
  }
}
</style>
