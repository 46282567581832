<!-- 咨询提问弹窗 -->
<template>
  <div class="askpop">
    <div class="box">
      <button class="close" @click='close'><svg class="icon"><use xlink:href="#iconshanchu"></use></svg></button>
      <h6><svg class="icon"><use xlink:href="#iconkefu"></use></svg>发表咨询</h6>
      <p class="info">声明：您可以在购买前对产品包装、颜色、运输、库存等方面进行咨询，我们有专人进行回复！因厂家随时会更新一些产品的包装、颜色、产地等参数，所以该回复仅在当时对提问者有效，其他网友仅供参考！咨询回复的工作时间为：周一至周五，9:00至18:00，请耐心等待工作人员回复。</p>
      <div class="group-div">
        <span>咨询类型：</span>
        <div class="radioBox">
          <el-radio-group v-model="radio">
            <el-radio :label="0">{{askType[0].ConsultingTypeName}}</el-radio>
            <el-radio :label="1">{{askType[1].ConsultingTypeName}}</el-radio>
            <el-radio :label="2">{{askType[2].ConsultingTypeName}}</el-radio>
            <el-radio :label="3">{{askType[3].ConsultingTypeName}}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="group-div">
        <span>咨询内容：</span>
        <div class="textArea">
          <textarea placeholder="请输入您想咨询的问题（500字内）" v-model='cnt'></textarea>
        </div>
      </div>
      <div class="group-div">
        <span>联系方式：</span>
        <div class="input">
          <input type="text" name="" v-model='phone'>
        </div>
      </div>
      <button class="btn" :class='{active:anonymity}' @click='btn'>匿名</button>
      <button class="postbtn" @click='postask'>提交</button>
    </div>
  </div>
</template>
<script>
import {regPhone} from '../../assets/js/common.js' //公用js
export default {
  name: 'askpop',
  data(){
    return {
      radio: 0,
      anonymity:false,
      cnt:'',
      phone:'',
    }
  },
  props: {
    msg: String,
    askType:Array,//咨询类型
    prId:String,//产品分类id（子类）
  },
  methods:{
    postask(){
      if(!this.phone){
        return this.$MessageBox.alert('请填写手机号',{type:'error'});
      }
      if(!regPhone(this.phone)){
        return this.$MessageBox.alert('手机号格式错误',{type:'error'});
      }
      // 提交提问
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Product/AddProductconsulting?ProductTypeId='+this.prId+'&ConsultingTypeId='+this.askType[this.radio].Id+'&content='+this.cnt+'&contactPhone='+this.phone+'&IsAnonymous='+(this.anonymity?1:0),this.keys())
        .then(res => {
            if(res.data.Success){
              this.$MessageBox.alert('提交成功',{type:'success',callback:()=>{
                this.radio = 0
                this.anonymity = false
                this.cnt = ''
                this.phone = ''
                this.$emit('exportClosePop')
              }});
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    btn(){
      // 是否匿名
      this.anonymity = !this.anonymity
    },
    close(){
      // 关闭弹窗
      this.$emit('taggeraskpop')
    }
  }
}
</script>
<style  lang="scss" scoped>
.askpop {
  width: 100%;height: 100%;position:fixed;top:0px;left:0px;background: rgba(0,0,0,0.5);z-index: 999;
  .box {
    width: 620px;height: 365px;background: #fff;position: absolute;top:calc((100vh - 365px)/2);left:calc((100vw - 620px)/2);border-radius: 10px;
    h6 {
      margin:0 25px;height: 40px;line-height: 40px;font-size: 16px;color:#ff9208;padding-top:10px;
      svg {
        fill: #ff9208;vertical-align: -5px;margin-right: 5px;width: 22px;height: 22px;
      }
    }
    .close {
      position: absolute;top:10px;right:10px;background: none;
      svg {
        width: 18px;height: 18px;fill: #555;
      }
    }
    .info {
      margin:0 25px;font-size: 14px;color:#9c9c9c;margin-bottom:10px;
    }
    .group-div {
      zoom:1;margin:0 25px;margin-bottom:10px;
      &:after {
        content: '';display: block;clear:both;
      }
      span {
        display: block;float:left;color:#515151;height: 20px;line-height: 20px;
      }
      .radioBox {
        float:left;margin-left:5px;
      }
      .textArea {
        float:left;margin-left:5px;
        textarea {
          width: 460px;height: 70px;border:1px solid #d2d2d2;padding:5px;font-size: 12px;
          &::placeholder {
            color:#ccc;
          }
        }
      }
      .input {
        float:left;margin-left:5px;margin-top:-3px;
        input {
          border:1px solid #d7d7d7;width: 130px;height: 24px;padding-left:5px;
        }
      }
    }
    .btn {
      width: 35px;height: 18px;line-height: 18px; display: block;margin-left:100px;font-size: 12px;color:#7e7e7e;border:1px solid #c4c1c1;border-radius: 3px;
    }
    .postbtn {
      width: 70px;height: 25px;margin:0 auto;display: block;border-radius: 20px;line-height: 25px;font-size: 12px;color:#fff;background-image: linear-gradient(to right, #f69017 , #ee785d);margin-top:5px;
    }
    .active {
      border:1px solid #409EFF;color:#409EFF;background: none;
    }
  }
}
</style>
