<!-- 产品品论摘要-->
<template>
  <div class="predetailremarkbrief">
      <div class="lts">
        <span>好评率</span><span>{{data.goodnum?Math.floor((data.goodnum/data.total)*10000)/100:0}}%</span>
      </div>
      <div class="rts">
        <div class="brief">
          <div class="top">
            <span>{{data.upnum}}</span>
            <span>{{data.midnum}}</span>
            <span>{{data.goodnum}}</span>
          </div>
          <div class="btm">
            <p>差评</p>
            <p>中评</p>
            <p>好评</p>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'predetailremarkbrief',
  data(){
    return {
    }
  },
  props: {
    data: Object
  },
  methods:{
  },
  mounted(){
    // console.log(this.data)
  }
}
</script>
<style  lang="scss" scoped>
.predetailremarkbrief {
  zoom:1;height: 110px;
  &:after {
    content: '';display: block;clear:both;
  }
  .lts {
    float:left;margin-top:25px;
    span {
      &:nth-child(1){
        font-size: 14px;color:#414141;position: relative;display: inline-block;padding-left:17px;margin-right:20px;
        &:after {
          content: '';display: block;width: 11px;height: 14px;background: #fe767c;top:3px;left:0px;position: absolute;
        }
      }
      &:nth-child(2){
        font-size:32px;font-weight: bold;color:#f13e55;vertical-align: -6px;
      }
    }
  }
  .rts {
    float:left;margin-left:100px;margin-top:40px;
    .brief {
      width: 355px;height: 14px;background-image: linear-gradient(to right, #ffeded , #fc5469);border-radius: 10px;
      .top {
        display: flex;justify-content: space-between;margin-top:-30px;float:left;width: 100%;
        span {
          height: 20px;border-radius: 20px;background: #f84d5b;top:0px;position: relative;text-align: center;color:#fff;padding:0 15px;z-index: 9;
          &:after {
            content: '';display: block;width: 9px;height: 9px;background: #f84d5b;position: absolute;bottom:-5px;left:calc((100% - 9px)/2);transform: rotate(45deg);z-index: -1;
          }
        }
      }
      .btm {
        display: flex;justify-content: space-between;float:left;width: 100%;margin-top:20px;
        p {
          font-size:14px;color:#555455;
        }
      }
    }
  }
}
</style>
