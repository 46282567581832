<!-- 产品详情 -->
<template>
  <div class="predetails">
    <div class="detailTop">
      <!-- 面包屑 -->
      <crumbs   :location='location' @exportCls='exportCls'></crumbs>
      <div class="details">
        <div class="lt">
          <!-- v-if='initpreshow' -->
          <preshow v-if='carouselImgBtm&&carouselImg' :carousels='carouselImg' height='450px' :carouselImgBtm='carouselImgBtm' ></preshow>
        </div>
        <div class="rt" >
          <!-- v-if='initpreinfo' :detailTypes='detailType'-->
          <preinfo  :CustomerID='CustomerID'  :carId='carId' :datas='preinfo' ></preinfo>
        </div>
      </div>
    </div>
    <div class="detailTop1">
      <!-- v-if='initprechoice' -->
      <prechoice  :data='prechoice'></prechoice>
    </div>
    <div class="detailCnt">
      <div class="lt">
        <!-- <storesearch></storesearch> -->
        <hotsearch :type='1'  ></hotsearch>
        <!-- v-if='initrelateclass' -->
        <relateclass  :data="relatecalssdata"></relateclass>
        <!-- :data='hotrecommenddata' -->
        <hotrecommend></hotrecommend>
      </div>
      <div class="rt">
        <!-- v-if='initpredetailtabs' -->
        <predetailtabs :prId='prId' :innerHtml='ProductDetail'></predetailtabs>
      </div>
    </div>
    <imghrs></imghrs>
  <!--   <div class="imghr">
      <a href=""><img  src="../assets/img/imghr/imghr.jpg"></a>
    </div> -->
  	<div class="layout">
  		<likes class='likes'></likes>
  		<infiniteImg type='0' class='infiniteImg'></infiniteImg>
  	</div>
  </div>
</template>
<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import crumbs from '@/components/crumbs/crumbs.vue';//面包屑导航
import preshow from '@/components/preshow/preshow.vue';//产品图片轮播
import preinfo from '@/components/preinfo/preinfo.vue';//产品信息
import prechoice from '@/components/prechoice/prechoice.vue';//商品精选
import relateclass from '@/components/relateclass/relateclass.vue';//相关分类
import storesearch from '@/components/storesearch/storesearch.vue';//店内搜索
import hotsearch from '@/components/hotsearch/hotsearch.vue';//带热门搜索组件
import hotrecommend from '@/components/hotrecommend/hotrecommend.vue';//热销推荐
import predetailtabs from '@/components/predetailtabs/predetailtabs.vue';//产品详情标签页
import imghrs from '@/components/imghrs/imghrs.vue';//产品详情标签页
import {Base64code} from '../assets/js/common.js' //公用js
export default {
  name: 'predetails',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:this.htmldescr
      },
      {
        name: "Keywords",
        content:this.htmlSeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'loading...',
      htmlSeoKey:"微微定wwding.com-纸巾定制上微微定 品质保障",
      htmldescr:"微微定wwding.com-纸巾定制上微微定 品质保障",
      location:[{Name:'首页'}],//面包屑
      carouselImg:null,//轮播图
      carouselImgBtm:null,
      // initpreshow:false,//轮播图组件加载参数
      preinfo:{
        // title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制微微定纸巾定制',
        // tip:['包邮','正规发票','放心购','秒杀'],
        // price:580,
        // oldPrice:889,
        // unitPrice:18,
        // texture1:['原生木浆','原生竹浆'],
        // texture2:['2层10片','3层10片'],
        // texture3:['200','500','1000','2000','4000'],
        // repertory:99999,//商品详细信息
        // countdown:{
        //   startTime:"2020-04-09 17:00",
        //   endTime:"2020-04-09 18:00",
        // }
      },
      carId:{id:''},//默认请求的添加购物车的ID
      // initpreinfo:false,
      prId:'',// 产品子类
      idcx:'',//产品分类-秒杀ID
      // idcx:'5e84547f56d56d2f9891c576',//产品分类-秒杀ID-如果是秒杀产品必传
      detailType:1,//产品类型1.普通、2.秒杀、 3.每日特卖、4.新品上市）
      CustomerID:'',//用户id
      prechoice:[
        // {url:require('../assets/img/prechoice/pre.jpg'),name:'微微定纸巾定制盒装纸巾微微定纸巾定制盒装纸巾',price:580},
        // {url:require('../assets/img/prechoice/pre1.jpg'),name:'微微定纸巾定制盒装纸巾微微定纸巾定制盒装纸巾',price:580},
        // {url:require('../assets/img/prechoice/pre2.jpg'),name:'微微定纸巾定制盒装纸巾微微定纸巾定制盒装纸巾',price:580},
        // {url:require('../assets/img/prechoice/pre3.jpg'),name:'微微定纸巾定制盒装纸巾微微定纸巾定制盒装纸巾',price:580},
        // {url:require('../assets/img/prechoice/pre4.jpg'),name:'微微定纸巾定制盒装纸巾微微定纸巾定制盒装纸巾',price:580},
        // {url:require('../assets/img/prechoice/pre5.jpg'),name:'微微定纸巾定制盒装纸巾微微定纸巾定制盒装纸巾',price:580},
      ],//商品精选
      // initprechoice:false,//商品精选加载变量
      relatecalssdata:[
        // {title:'方盒纸巾',list:[{title:'方盒高度40mm'},{title:'方盒高度30mm'}]},
        // {title:'荷包纸巾',list:[{title:'原生木浆：3层'},{title:'原生木浆：2层'},{title:'竹浆：3层'}]},
        // {title:'手帕纸巾',list:[{title:'方盒高度40mm'},{title:'方盒高度30mm'}]},
        // {title:'盒装抽纸',list:[{title:'170X105X35mm'},{title:'170X105X35mm'},{title:'170X105X35mm'}]},
        // {title:'扑克盒纸巾',list:[{title:'方盒高度40mm'},{title:'方盒高度30mm'}]},
        // {title:'湿纸巾',list:[{title:'方盒高度40mm'},{title:'方盒高度30mm'}]},
        // {title:'定制方巾',list:[{title:'方盒高度40mm'},{title:'方盒高度30mm'}]},
        // {title:'奶茶杯',list:[{title:'方盒高度40mm'},{title:'方盒高度30mm'}]},
        // {title:'广告纸巾定制',list:[{title:'荷包纸巾'}]},
      ],//相关分类数据
      // initrelateclass:false,//相关分类数据加载变量
      // hotrecommenddata:[
      //   {url:require('../assets/img/hotrecommend/hot.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot1.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot2.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot1.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot2.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      //   {url:require('../assets/img/hotrecommend/hot1.jpg'),title:'微微定纸巾定制微微定纸巾定制微微定纸巾定制',price:580},
      // ],//热销产品数据
      // initpredetailtabs:false,//产品详情OR其他评论和咨询
      ProductDetail:'',// 产品介绍html
    }
  },
  components: {
  	likes,
  	infiniteImg,
    crumbs,
    preshow,
    preinfo,
    prechoice,
    relateclass,
    storesearch,
    hotrecommend,
    predetailtabs,
    imghrs,
    hotsearch
  },
  props: {
    msg: String
  },
  methods:{
    exportCls(i){
      // 面包屑导航
      console.log(i)
      if(i.Name=='首页'){
        this.$router.push('/index.html')
      }
      if(i.Id){
        var _searchQueryObj = {
          key:'',
          orderBy:0,
          OrderT:1,
          parentId:i.Id,
          startPrice:0,
          endPrice:0,
          index:1
        }
        window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
        window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
        this.$router.push({path:'/classlist.html'})
      }
    },
    localReset(){
      // this.detailType = this.$route.query.detailType
      // var _Base64code = new Base64code()
      // var _exp = /(\S*)\.html/i
      // this.prId = this.$route.params.id
      // this.idcx = this.$route.params.idcx==-1?'':this.$route.params.idcx
      // this.detailType = this.$route.params.detailType.match(_exp)[1]
      // 读取用户id
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      // end
      // 产品详情--请求数据
      this._axios({then:()=>{
        // '&type='+this.detailType+
        console.log()
        this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductDeail?productSubTypeID='+this._spf(this.$route.query.id)+'&dynamicId='+this._spf(this.$route.query.idcx),this.keys({token:this._token({method:'get'})}))
        .then(res => {
          if(res.data.Success){
            // 图片地址
            var _data = [];
            var _obj = {};
                _obj.arr = [];
            if(res.data.Data.Dzproductimglist&&res.data.Data.Dzproductimglist.length>0){
              if(res.data.Data.Dzproductimglist.length<5){
                for(let i in res.data.Data.Dzproductimglist){
                  _obj.arr.push(res.data.Data.Dzproductimglist[i]);
                }
                _data.push(_obj)
              }else{
                for(let i in res.data.Data.Dzproductimglist){
                  _obj.arr.push(res.data.Data.Dzproductimglist[i]);
                  if(i>0&&(parseInt(i)+1)%5==0){
                    _data.push(_obj)
                    _obj = {};
                    _obj.arr = [];
                  }
                }
                if(res.data.Data.Dzproductimglist.length%5!=0){
                  _data.push(_obj)
                }
              }
            }
            // 图片地址
            this.carouselImgBtm = _data
            // console.log(this.carouselImgBtm)
            this.carouselImg = res.data.Data.Dzproductimglist
            // this.initpredetailtabs = true;
            // this.initpreshow = true;
            if(res.data.Data.SecKill&&res.data.Data.SecKill.StartDate){
              res.data.Data.SecKill.StartDate = res.data.Data.SecKill.StartDate.split('T')[0]+' '+res.data.Data.SecKill.StartDate.split('T')[1]
              res.data.Data.SecKill.EndDate = res.data.Data.SecKill.EndDate.split('T')[0]+' '+res.data.Data.SecKill.EndDate.split('T')[1]
            }
            // console.log(res.data.Data.SecKill)
            this.ProductDetail =(res.data.Data.SpecParameter?res.data.Data.SpecParameter:' ')+(res.data.Data.ProductDetail?res.data.Data.ProductDetail:'')
            var _obj1 = {Name:res.data.Data.ParentName,Id:res.data.Data.ParentID};
            var _obj2 = {Name:res.data.Data.AttachTypeName};
            this.location.push(_obj1,_obj2)
            // this._settitle({title:res.data.Data.AttachTypeName})
            this.htmltitle = res.data.Data.SeoTitle
            this.htmlSeoKey = res.data.Data.SeoKey
            this.htmldescr = res.data.Data.SeoDescrition
            // console.log(res.data.Data)
            this.carId.Id = res.data.Data.ProductId
            this.$set(this,'preinfo',res.data.Data)
            // console.log(res.data.Data)
            // 根据规格、材质、数量等参数请求价格
            // this._axios({then:()=>{
            //   // res.data.Data.DzspecificationsId = res.data.Data.DzspecificationsId?res.data.Data.DzspecificationsId:''
            //   // res.data.Data.DzspecificationsId = null
            //   this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductPrice?DzmaterialId='+res.data.Data.DzmaterialId+'&DzspecificationsId='+res.data.Data.DzspecificationsId+'&DzproductnumberId='+res.data.Data.DzproductnumberId,this.keys({token:this._token({method:'get'})}))
            //   .then(res => {
            //       if(res.data.Success){
            //         if(res.data.Data){
            //           // this.initpreinfo = true;
            //           this.CustomerID = CustomerID
            //           this.$set(this.preinfo,'Price',res.data.Data.Price)
            //           this.$set(this.preinfo,'Stock',res.data.Data.Stock)
            //           this.carId.Id = res.data.Data.Id
            //         }
            //       }else{
            //         // this.$MessageBox.alert('2',{type:'error'});
            //       }
            //     }, res => {
            //       // this.$MessageBox.alert('系统异常',{type:'error'});
            //     })
            //   }})
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
      // end
      // 详情页下的商品精选--请求
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductOtherList?productSubTypeID='+this._spf(this.$route.query.id),this.keys())
        .then(res => {
            if(res.data.Success){
              this.$set(this,'prechoice',res.data.Data.list)
              // this.initprechoice=true
            }else{
              // this.$MessageBox.alert('4',{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // end
      // 相关分类
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductTypeList',this.keys())
        .then(res => {
            if(res.data.Success){
              // this.initrelateclass = true
              this.$set(this,'relatecalssdata',res.data.Data.tlist)
            }else{
              // this.$MessageBox.alert('1',{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // end
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 读取url参数
      this.prId = this._spf(this.$route.query.id)
      this.idcx = this._spf(this.$route.query.idcx)
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
}
</script>
<style  lang="scss" scoped>
.predetails {
  .detailTop {
    background: #fff;width: 100%;border-bottom:1px solid #f3f3f3;
    .details {
      width: 1200px;margin:0 auto;zoom:1;margin-top:10px;padding-bottom:40px;
      &:after {
        content: '';display: block;clear:both;
      }
      .lt {
        float:left;width: 450px;
      }
      .rt {
        float:right;width: 725px;
      }
    }
  }
  .detailTop1 {
    background: #fff;width: 100%;
  }
  .imghr {
    width: 1200px;margin:0 auto;margin-top:20px;
    a {
      img {
        display: block;border-radius: 10px;
      }
    }
  }
  .detailCnt {
    width: 1200px;margin:0 auto;margin-top:20px;zoom:1;
    &:after {
      content: '';display: block;clear:both;
    }
    .lt {
      width: 282px;float:left;position: relative;background: none;padding-top:6px;
      &:after {
        content: '';display: block;width: 282px;height: 6px;background: #fe767c;border-radius: 5px;top:0px;left:0px;position: absolute;
      }
    }
    .rt {
      width: 894px;float:right;
    }
  }
	.layout {
		width: 1200px;margin:0 auto;position: relative;
		.likes {
		    width: 1200px;overflow: hidden; margin-top:40px;
		}
		.infiniteImg {
			margin-top:40px;
		}
	}
}
</style>
