<!-- 产品信息 -->
<template>
  <div class="content" >
    <h6 class="h6">{{datas.AttachTypeName}}</h6>
    <div class="pertop"  :class='{countdownpd:datas.activeType&&datas.activeType==2?true:false}'>
      <div class="countdown" v-if='datas.activeType&&datas.activeType==2'>
        <div class="lt">
          <svg class="icon"><use xlink:href="#iconshizhong"></use></svg>
          <span>限时秒杀</span>
        </div>
        <div class="rt">
          <span class="sp">{{datas.SecKill | limitType}}</span>
          <div class="rts" v-show='time!=2'>
            <span class="sps">{{h}}</span><span class="sps1">:</span>
            <span class="sps">{{m}}</span><span class="sps1">:</span>
            <span class="sps">{{s}}</span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span v-for='i,n in tiparrs(datas.AttachTypeDeailName)' :key='n'><i>·</i>{{i}}</span>
      </div>
       <div class="group-price price1" >
          <span class="sp">促销价</span><p><span>&yen;</span>{{datas.Price?datas.Price:'' | formatPrice}}</p><p>原价<span>&yen;{{datas.originalPrice?datas.originalPrice:'' | formatPrice}}</span></p>
       </div>
      <div class="group-price price2" v-if='datas.activeType&&datas.activeType==1'>
        <span class="sp">单价</span><p><span>&yen;{{datas.unitPrice?datas.unitPrice:''}}</span></p>
      </div>
      <div class="group-price price2" v-if='datas.VoucherList&&datas.VoucherList.length>0'>
        <!-- <span class="mj">满减</span> -->
        <span class="sp">促销信息</span><p style="color:#da211d;">
          <span class="coupons" @click='getcoupon(csls)' v-for='csls,cxlsi in datas.VoucherList' :key='cxlsi'>{{csls.VoucherInfo}}</span>
        </p>
      </div>
    </div>
    <div class="group-texture"  v-if='datas.DzspecificationsList'>
      <span class="sp">规格</span><p><button  :class='{active:datas.DzspecificationsId==i.Id}' @click='setActive2(i)' :disabled='i.IsDisabled' v-for='i,n in datas.DzspecificationsList'>{{i.SpeName}}</button></p>
    </div>
    <div class="group-texture"  v-if='datas.DzmaterialList'>
      <span class="sp">材质</span><p><button :class='{active:datas.DzmaterialId==i.Id}' @click='setActive1(i)'  :disabled='i.IsDisabled'  v-for='i,n in datas.DzmaterialList'>{{i.MaterialName }}</button></p>
    </div>
    <div class="group-texture" v-if='datas.DzproductnumberList'>
      <span class="sp">数量</span><p><button  :class='{active:datas.DzproductnumberId==i.Id}' @click='setActive3(i)'  :disabled='i.IsDisabled' v-for='i,n in datas.DzproductnumberList'>{{i.NumberName}}</button></p>
    </div>
    <div class="buy">
      <div class="lt">购买数量：</div>
      <div class="rt">
         <el-input-number v-model="num"  size="small" controls-position="right"  :min="1" :max="datas.Stock"></el-input-number><span>件</span>
      </div>
      <div class="rt">
        库存{{datas.Stock}}件
      </div>
    </div>
    <div class="slt">
      <div class="lt">您已选择：</div>
      <div class="rt">
        {{num}}件
      </div>
    </div>
    <div class="group-menus">
      <button @click='buynow' :disabled="time!=1&&datas.activeType==2" :class='{disable:time!=1&&datas.activeType==2}'>立即购买</button>
      <button @click='joinCar' :disabled="time!=1&&datas.activeType==2||waitBuy" :class='{disable:time!=1&&datas.activeType==2}' >加入购物车</button>
    </div>
    <div class="other">
      <h6><svg class="icon"><use xlink:href="#iconfuwuchengnuo1"></use></svg>服务承诺</h6>
      <div  class="tip">
        <span>2小时快速响应</span><span>一对一专属设计</span><span>大部分地区包邮</span><span>质量问题免费重做</span>
      </div>
    </div>
<!--     <button class="share">
      <svg class="icon"><use xlink:href="#iconkefu"></use></svg>
      分享
    </button> -->
  </div>
</template>
<script>
import {mixin,startTimeCount,endTimeCount,setCarlist} from '../../assets/js/common.js' //公用js
export default {
  name: 'preinfo',
  mixins: [mixin],//混入
  data(){
    return {
      num: 1,
      h:0,
      m:0,
      s:0,
      time:0,
      t:0,//定时器1
      t1:0,//定时器2
      productData:{
        price:null,
        buynumber:1,
        caizhi:null,
        guige:null,
        shuliang:null,
        productTypeid:null
      },
      that:this,//组件this对象
      waitBuy:false,
    }
  },
  props: {
    datas: Object,
    discountid:null,
    detailTypes:null,//1.普通、2.秒杀、 3.每日特卖、4.新品上市）
    CustomerID:null,//用户id
    carId:null,//添加到购物车的产品id
  },
  methods:{
    getcoupon(i){
      // 领券
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      if(!CustomerID){
        return this.$store.dispatch("cmtlogintagger",!this.$store.state.logintagger)
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/CollectCoupons?VoucherId='+i.Id,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.$MessageBox.alert(res.data.Data.message,{type:'success'});
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    // formatPrice
    setActive1(i){
      // 素材
      this.datas.DzmaterialId= i.Id
      this._axios({then:()=>{
      // 根据规格、材质、数量等参数请求价格
      // this.DzspecificationsId = this.DzspecificationsId?this.DzspecificationsId:''
      this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductPrice?DzmaterialId='+this.datas.DzmaterialId+'&DzspecificationsId='+this.datas.DzspecificationsId+'&DzproductnumberId='+this.datas.DzproductnumberId+'&productSubTypeID='+this.datas.Id+'&key=1',this.keys({token:this._token({method:'get'})}))
      .then(res => {
          if(res.data.Success){
            if(res.data.Data.message!=''){
              this.$Message({
                  message: res.data.Data.message,
                  type: 'warning',
                  duration:1500,
              });
            }
            this.$set(this.datas,'Price',res.data.Data.Price)
            this.$set(this.datas,'Stock',res.data.Data.Stock)
            this.$set(this.datas,'unitPrice',res.data.Data.unitPrice)
            this.$set(this.datas,'originalPrice',res.data.Data.originalPrice)

            this.$set(this.datas,'DzspecificationsList',res.data.Data.DzspecificationsList)
            this.$set(this.datas,'DzmaterialList',res.data.Data.DzmaterialList)
            this.$set(this.datas,'DzproductnumberList',res.data.Data.DzproductnumberList)
            this.num = 1;
            this.carId.Id = res.data.Data.Id
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    setActive2(i){
      // 规格
      this.datas.DzspecificationsId=  i.Id
      this._axios({then:()=>{
      // 根据规格、材质、数量等参数请求价格
      // this.DzspecificationsId = this.DzspecificationsId?this.DzspecificationsId:''
      this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductPrice?DzmaterialId='+this.datas.DzmaterialId+'&DzspecificationsId='+this.datas.DzspecificationsId+'&DzproductnumberId='+this.datas.DzproductnumberId+'&productSubTypeID='+this.datas.Id+'&key=0',this.keys({token:this._token({method:'get'})}))
      .then(res => {
          if(res.data.Success){
            if(res.data.Data.message!=''){
              this.$Message({
                  message: res.data.Data.message,
                  type: 'warning',
                  duration:1500,
              });
            }
            this.$set(this.datas,'Price',res.data.Data.Price)
            this.$set(this.datas,'Stock',res.data.Data.Stock)
            this.$set(this.datas,'unitPrice',res.data.Data.unitPrice)
            this.$set(this.datas,'originalPrice',res.data.Data.originalPrice)

            this.$set(this.datas,'DzspecificationsList',res.data.Data.DzspecificationsList)
            this.$set(this.datas,'DzmaterialList',res.data.Data.DzmaterialList)
            this.$set(this.datas,'DzproductnumberList',res.data.Data.DzproductnumberList)
            this.num = 1;
            this.carId.Id = res.data.Data.Id
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    setActive3(i){
      // 数量
      this.datas.DzproductnumberId=  i.Id
      this._axios({then:()=>{
      // 根据规格、材质、数量等参数请求价格
      // this.DzspecificationsId = this.DzspecificationsId?this.DzspecificationsId:''
      this.axios.post(process.env.VUE_APP_URL+'Product/ObtainProductPrice?DzmaterialId='+this.datas.DzmaterialId+'&DzspecificationsId='+this.datas.DzspecificationsId+'&DzproductnumberId='+this.datas.DzproductnumberId+'&productSubTypeID='+this.datas.Id+'&key=2',this.keys({token:this._token({method:'get'})}))
      .then(res => {
          if(res.data.Success){
            if(res.data.Data.message!=''){
              this.$Message({
                  message: res.data.Data.message,
                  type: 'warning',
                  duration:1500,
              });
            }
            this.$set(this.datas,'Price',res.data.Data.Price)
            this.$set(this.datas,'Stock',res.data.Data.Stock)
            this.$set(this.datas,'unitPrice',res.data.Data.unitPrice)
            this.$set(this.datas,'originalPrice',res.data.Data.originalPrice)

            this.$set(this.datas,'DzspecificationsList',res.data.Data.DzspecificationsList)
            this.$set(this.datas,'DzmaterialList',res.data.Data.DzmaterialList)
            this.$set(this.datas,'DzproductnumberList',res.data.Data.DzproductnumberList)
            this.num = 1;
            this.carId.Id = res.data.Data.Id
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
    },
    joinCar(){
      if(!this.datas.Price||this.datas.Price==0){
       this.$MessageBox.alert('产品没有报价，无法加入购物车！',{type:'error'}).catch(() => {
                  // console.log('再逛逛')
                });
       return;
      }
      if(!this.datas.Stock||this.datas.Stock==0){
        this.$MessageBox.alert('产品库存不足，无法加入购物车！',{type:'error'}).catch(() => {
                  // console.log('再逛逛')
                });
        return;
      }
      // console.log(this.carId.Id)
      // return;
      // 加入购物车
      this.productData = {
        buynumber:this.num,
        carId:this.carId.Id,
        type:this._spf(this.$route.query.detailType),
        idcx:this._spf(this.$route.query.idcx),
        productTypeid:this._spf(this.$route.query.id)
      }
      if(this.$store.state.user&&this.$store.state.user.CustomerID){
        this._axios({then:()=>{
          // +'&SellingTypes='+this.$route.query.detailType
          this.axios.post(process.env.VUE_APP_URL+'Shop/BatchAddShoppingCart?productIds='+this.productData.carId+'&nums='+this.productData.buynumber+'&dynamicIds='+this._spf(this.$route.query.idcx),this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.shopCount))
                this.$MessageBox.confirm('商品成功加入购物车', {
                  confirmButtonText: '去结算',
                  cancelButtonText: '再逛逛',
                  type: 'success',
                  customClass:'joinCar'
                }).then(() => {
                  this._router({then:()=>{
                    this.$router.push({path:'/buycar.html'})
                  }})
                  // console.log('去结算')
                }).catch(() => {
                  // console.log('再逛逛')
                });
                // end
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
        }})
      }else{
        // this.productData存入本地进入购物车获取本地加用户信息中的数据
        setCarlist({method:'set',data:this.productData})
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
        }
        // else{
        //   this.$store.dispatch('cmtBuycarum',0)
        // }
        // end
        // 加入购物车
        this.$MessageBox.confirm('商品成功加入购物车', {
          confirmButtonText: '去结算',
          cancelButtonText: '再逛逛',
          type: 'success',
          customClass:'joinCar'
        }).then(() => {
          this._router({then:()=>{
            this.$router.push({path:'/buycar.html'})
          }})
          // console.log('去结算')
        }).catch(() => {
          console.log('再逛逛')
        });
        // end
      }
    },
    tiparrs(v){
      var TEL_REGEXP = /，/;
      var arr=[];
      if(!v){
        return null
      }
      if(TEL_REGEXP.test(v)){
        arr = v.split('，')
      }else{
        arr = v.split(',')
      }
      return arr;
    },
    buynow(){
      if(!this.datas.Price||this.datas.Price==0){
       this.$MessageBox.alert('产品没有报价，无法购买！',{type:'error'}).catch(() => {
                  // console.log('再逛逛')
                });
       return;
      }
      if(!this.datas.Stock||this.datas.Stock==0){
        this.$MessageBox.alert('产品库存不足，无法购买！',{type:'error'}).catch(() => {
                  // console.log('再逛逛')
                });
        return;
      }
      // 立即购买
      this.waitBuy = true
      this._axios({exp:true,then:()=>{
        // 验证登录
        this.productData = {
          buynumber:this.num,
          carId:this.carId.Id,
        }
        // '&SellingType='+this.$route.query.detailType+
        this.axios.post(process.env.VUE_APP_URL+'Shop/payBuy?productId='+this.productData.carId+'&num='+this.productData.buynumber+'&dynamicId='+this._spf(this.$route.query.idcx),this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.waitBuy = false
              this.$router.push({path:'/settlebill',query:{_ids:res.data.Data.jobID,nums:this.productData.buynumber+'.html'}})
            }else{
              this.waitBuy = false
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            this.waitBuy = false
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },catch:()=>{
        // 用户不存在
        // this.$router.push({path:'accountlogin',query:{redirect:'/predetail?id='+this.$route.query.id+'&idcx='+this.$route.query.idcx+'&detailType='+this.$route.query.detailType}})
        this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
      }})
    },
    timess(){
      // 倒计时
      if(this.datas.SecKill&&this.datas.SecKill.StartDate){
        var nowTime = Date.now();
        var strTime = new Date(this.datas.SecKill.StartDate).getTime();
        var endTime = new Date(this.datas.SecKill.EndDate).getTime();
        var tim = strTime - nowTime; //时间差
        var tim1 = endTime - nowTime; //时间差
        if(tim >= 0){
          this.t = setInterval(()=>{
            var time = startTimeCount(this.datas.SecKill.StartDate)
            this.h = time.hs
            this.m = time.m
            this.s = time.s
          },1000)
          this.time=0
          return;
        }
        if(tim1 >= 0){
          this.t1 = setInterval(()=>{
            var time = startTimeCount(this.datas.SecKill.EndDate)
            this.h = time.hs
            this.m = time.m
            this.s = time.s
          },1000)
          this.time=1
          return
        }
        this.time=2
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
     
    })
  },
  filters:{
    limitType(v){
      // console.log(v.StartDate,v.EndDate)
      if(v){
        var nowTime = Date.now();
        var strTime = new Date(v.StartDate).getTime();
        var endTime = new Date(v.EndDate).getTime();
        var tim = strTime - nowTime; //时间差
        var tim1 = endTime - nowTime; //时间差
        if(tim >= 0){
          return "即将开始";
          // if(tim>600000){
          //   return "准点开枪";
          // }
          // if(tim<600000){
          //   return "即将开始";
          // }
        }
        if(tim1 >= 0){
          return "即将结束";
          // if(tim1>600000){
          //   return "正在秒杀";
          // }else{
          //   return "即将结束";
          // }
        }
        return "已经结束";
      }
    },
  },
  destroyed(){
    // 组件销毁清空定时器
    clearInterval(this.t)
    clearInterval(this.t1)
  },
  watch:{
    datas:function(n,o){
      this.timess()
    }
  }
}
</script>
<style lang="scss">
.joinCar {
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
}

</style>
<style  lang="scss" scoped>
.content {
  width: 725px;float:right;
  .h6 {
     overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2; line-clamp: 2;-webkit-box-orient: vertical;font-size: 16px;line-height: 25px;
  }
  .pertop {
    width: 725px;float:right;background: url('../../assets/img/preinfo/topbg.jpg') 0px 0px repeat;border-radius: 10px;margin-top:10px;margin-bottom:15px;padding-bottom: 10px;
    .countdown {
      width: 100%;height: 30px;line-height: 30px;background: #fc6b70;border-top-left-radius: 10px;border-top-right-radius: 10px;zoom:1;
      &:after {
        content: '';display: block;clear:both;
      }
      .lt {
        float:left;
        svg {
          fill: #fff;vertical-align: -7px;width: 25px;height: 25px;margin-left:8px;
        }
        span {
          font-size:14px;color:#fff;margin-left:10px;
        }
      }
      .rt {
        float:right;
        .sp {
          float:left;color:#fff;font-size: 14px;margin-right: 10px;
        }
        .rts {
          float:left;margin-right: 20px;color:#fff;
          .sps {
            background: #fff;color:#464646;display: block;float:left;width: 22px;height: 22px;margin-top:4px;line-height: 22px;text-align: center;font-size: 14px;
          }
          .sps1 {
            float:left;margin:0 2px;
          }
        }
      }
    }
    .tip {
      margin-top:10px;margin-left:20px;zoom:1;
      &:after {
        content: '';display: block;clear:both;
      }
      span {
        background: #fff;border-radius: 20px;margin-left:10px;float:left;display: inline-block;height: 26px;line-height: 26px;font-size: 12px;padding:0 20px;padding-bottom:3px;margin-bottom:10px;
        i {
          font-style: normal;font-size: 30px;vertical-align: -5px;color:#f84e5d;margin-right: 5px;height: 26px;line-height: 26px;display: inline-block;
        }
      }
    }
    .group-price {
      zoom:1;
      &:after {
        content: '';display: block;clear:both;
      }
      .sp {
        color:#9f9c9b;font-size: 14px;margin-left:30px;display: block;float:left;width: 56px;
      }
      p {
        float:left;margin-left:20px;
        .mj {
          border:1px solid #da211d;padding:5px;font-size: 12px;margin-right: 5px;
        }
        .coupons {
          border:1px solid #da211d;cursor: pointer;font-size: 12px;padding:2px 5px;margin-right:5px;
        }
      }
    }
    .price1 {
      margin-top:15px;
      p {
        &:nth-child(2) {
          color:#da211d;font-size: 28px;font-weight: bold;margin-top:-13px;
          span {
            color:#da211d;font-size: 18px;margin-right:3px;
          }
        }
        &:nth-child(3) {
          color:#8e8c8d;font-size: 14px;
          span {
            text-decoration: line-through;margin-left:3px;
          }
        }
      }
    }
    .price2 {
      margin-top:10px;
      p {
        color:#252524;font-size: 14px;
        span {
          color:#da211d;
        }
      }
    }
  }
  .countdownpd {
    padding-bottom:15px;
  }
  .group-texture {
     zoom:1;margin-top:15px;float:left;
      &:after {
        content: '';display: block;clear:both;
      }
      .sp {
        color:#9f9c9b;font-size: 14px;margin-left:30px;display: block;float:left;width: 50px;
      }
      p {
        float:left;margin-left:20px;width: 475px;margin-top:-8px;
        button {
          border:1px solid #dedee0;padding:5px 15px;border-radius: 20px;margin-right: 10px;cursor: pointer;float:left;margin-bottom:6px;font-size: 12px;background: #fff;color:#333;
          &:hover {
            opacity: 0.8;
          }
          &:disabled {
            background: #eaeaea;border-color:#eaeaea;color:#ccc;
          }
        }
        .active {
          border:1px solid #fa5467;
        }
      }
  }
  .buy {
    margin-top:25px;width: 100%;zoom:1;float:left;
    &:after {
      content: '';display: block;clear:both;
    }
    .lt {
      float:left;margin-left:25px;width: 70px;color:#666666;font-size: 14px;
    }
    .rt {
      float:left;margin-left:10px;
      &:nth-child(2) {
        margin-top:-5px;
        /deep/ .el-input-number--small {
          width: 80px;
          .el-input__inner {
            padding-left: 0px;padding-right: 32px;
          }
        }
        span {
          margin-left:5px;
        }
      }
    }
  }
  .slt {
    margin-top:15px;width: 100%;zoom:1;float:left;
    &:after {
      content: '';display: block;clear:both;
    }
    .lt {
      float:left;margin-left:25px;width: 70px;color:#666666;font-size: 14px;
    }
    .rt {
      float:left;margin-left:10px;color:#666666;font-size: 14px;
    }
  }
  .group-menus {
    margin-left:25px;margin-top:25px;float:left;
    button {
      width: 180px;height: 44px;border-radius: 50px;margin-right: 20px;text-align: center;line-height: 44px;color:#fff;background: #fb546b;font-size:16px;
      &:nth-child(2) {
        background: #ffefec;border:1px solid #fb546b;width: 178px;height: 42px;line-height: 42px;color:#fb546b;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .disable {
      background: #333333;
      &:hover {
        background: #333333;opacity: 1;cursor:no-drop;
      }
      &:nth-child(2) {
        background: #333333;border:1px solid #333333;width: 178px;height: 42px;line-height: 42px;color:#fff;
      }
    }
  }
  .other {
    float:left;width: 100%;margin-left:25px;margin-top:35px;
    h6 {
      font-size: 14px;color:#252524;
      svg {
        fill: #21a5f7;vertical-align: -3px;margin-right: 5px;
      }
    }
    .tip {
      margin-top:5px;
      span {
        padding:5px 20px;background: #7bc9f8;border-radius: 5px;font-size: 14px;color:#fff;margin-right: 15px;float:left;margin-top:10px;
        &:nth-child(2) {background: #f79fa1;}
        &:nth-child(3) {background: #fcd7a1;}
        &:nth-child(4) {background: #93e7dc;}
      }
    }
  }
  .share {
    float:right;background: none;font-size: 14px;color:#252524;width: 60px;height: 60px;text-align: center;
    svg {
      fill: #21a5f7;display: block;margin:0 auto;width: 40px;height: 40px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
