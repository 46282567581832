<!-- 提问咨询列表 -->
<template>
  <div class="asklist">
    <div class="list" v-for='i,n in data' :key='n'>
      <h6 class="h6">{{i.IsAnonymous==1?"匿名用户":"用户："}}{{i.IsAnonymous==1?"":i.ContactPhone | formatPhoneHide}}<span class="span1">{{i.CreateDate | timefiter(1)}}</span><span class="span">{{i.ConsultingTypeName}}</span></h6>
      <p class="msg">{{i.Content}}</p>
      <p class="time"></p>
      <h6 v-if='i.ReplyContent' class="h7 h8">{{i.ReplyDate.split('T')[0]}}<span>平台回复</span></h6>
      <p v-if='i.ReplyContent' class="msg msg1">{{i.ReplyContent}}</p>
    </div>
    <button @click='moreList'  v-show='total>data.length'>查看更多</button>
  </div>
</template>
<script>
import {mixin,timefiter} from '../../assets/js/common.js' //公用js
export default {
  name: 'asklist',
  mixins: [mixin],//混入
  props: {
    data: Array,
    total:Number
  },
  methods:{
    moreList(){
      // 加载更多数据
      this.$emit('exportMore1')
    }
  }
}
</script>
<style  lang="scss" scoped>
.asklist {
  .list {
    border-bottom:1px solid #ececec;margin-bottom:15px;padding:0 20px;
    .h6 {
      font-size: 14px;color:#252525;height: 40px;line-height:40px;margin-bottom:5px;
      .span1 {
        font-weight: normal;font-size: 14px;margin-left:20px;color:#ccc;
      }
      .span {
        font-weight: normal;padding:5px 15px;background: #f3f3f3;border-radius: 5px;font-size: 12px;vertical-align: 2px;margin-left:20px;
      }
    }
    .h7 {
      font-size: 14px;font-weight: normal;color:#999;
      span {
        margin-left:10px;color:#999;
      }
    }
    .h8 {
      color:#fe7c80;
      span {
        color:#fe7c80;
      }
    }
    .msg {
      color:#787777;margin-bottom:8px;
    }
    .time {
      color:#a3a3a2;margin-bottom:10px;
    }
    .msg1 {
      color:#fe7c80;margin-bottom:15px;margin-top:5px;
    }
  }
  button {
    background: none;display: block;width: 100%;height: 40px;line-height: 40px;margin-top:40px;margin-bottom:20px;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
