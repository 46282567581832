<!-- 店内搜索 -->
<template>
  <div class="storesearch">
    <h6>店内搜索</h6>
    <input type="text" placeholder="扑克盒装纸巾">
    <button><svg class="icon"><use xlink:href="#iconziyuan3"></use></svg></button>
  </div>
</template>
<script>
export default {
  name: 'storesearch',
  props: {
    msg: String
  },
  methods:{
  }
}
</script>
<style  lang="scss" scoped>
.storesearch {
  position: relative;background: #fff;
  h6 {
    position: relative;margin:0 14px;height: 24px;line-height: 24px;font-size: 14px;padding-left:13px;color:#252525;padding-top:17px;
    &:after{
      content: '';display: block;position: absolute;width: 8px;height: 24px;background: #fe7c80;border-radius: 5px;top:17px;left:0px;
    }
  }
  input {
    width: 238px;height: 36px;background: #f3f3f3;border:1px solid #b7b6b4;border-radius: 20px;display: block;margin:0 auto;margin-top:15px;line-height: 36px;font-size: 14px;padding-left:20px;color:#333;
    &::placeholder {
      color:#bebdbd;
    }
  }
  button {
    position: absolute;top:68px;right:28px;width: 16px;height: 16px;background: none;
    svg {
      fill: #040203;width: 16px;height:16px;
    }
    &:hover {
      transform: scale(1.2);opacity: 0.8;
    }
  }
}
</style>
