<!-- 热销推荐 -->
<template>
  <div class="hotrecommend">
     <h6>热销推荐</h6>
     <div class="list" v-for='i,n in datas' :key='n' @click='links(i)'>
     	<div class="box">
     		<img :src="i.url">
     	</div>
     	<h5 :title='i.title'>{{i.title}}</h5>
     	<p><span>&yen;</span>{{i.price | formatPrice}}</p>
     </div>
  </div>
</template>
<script>
import {mixin} from '../../assets/js/common.js' //公用js
export default {
  name: 'hotrecommend',
  mixins: [mixin],//混入
  data(){
  	return {
  		datas:[]
  	}
  },
  props: {
    data: Array
  },
  methods:{
  	links(i){
  		// 跳转详情
  		this._router({then:()=>{
	        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
	        var id = i.Id + '.html'
	        this._open({path:'/predetail',query:{idcx:'',id:id,}})
	    }})
  	}
  },
  mounted(){
  	this.$nextTick(()=>{
  		var CustomerID='';
	      if(this.$store.state.user){
	        if(this.$store.state.user.CustomerID){
	          CustomerID = this.$store.state.user.CustomerID
	        }
	      }
  		// 请求热销产品
  		this._axios({then:()=>{
	      this.axios.post(process.env.VUE_APP_URL+'Home/GetProductHotOrCustomized?type=1&CustomerID='+CustomerID,this.keys())
	      .then(res => {
	          if(res.data.Success){
          		var _data = [];
	            for(let i in res.data.Data){
	              var _obj = {};
	              _obj.url = this._src + res.data.Data[i].ImagePath
	              _obj.title = res.data.Data[i].AttachTypeName
	              _obj.price = res.data.Data[i].Price
	              _obj.Id = res.data.Data[i].Id
	              _data.push(_obj)
	            }
            	this.$set(this,'datas',_data)
	          }else{
	            this.$MessageBox.alert(res.data.Error,{type:'error'});
	          }
	        }, res => {
	          // this.$MessageBox.alert('系统异常',{type:'error'});
	        })
	    }})
	    // end
  	})
  }
}
</script>
<style  lang="scss" scoped>
.hotrecommend {
	h6 {
	    position: relative;margin:0 14px;padding-top:24px;height: 24px;line-height: 24px;font-size: 14px;padding-left:13px;color:#252525;margin-bottom:10px;
	    &:after{
	      content: '';display: block;position: absolute;width: 8px;height: 24px;background: #fe7c80;border-radius: 5px;top:24px;left:0px;
	    }
	}
	.list {
		height: 258px;width: 100%;background: #fff;border-radius: 10px;margin-bottom:10px;cursor: pointer;
		&:hover {
			transform: scale(1.02);
		}
		.box {
			height: 180px;display: flex;justify-content: center;align-items: center;
			img {
				display: block;max-width: 150px;max-height: 150px;
			}
		}
		h5 {
			width: 170px;margin:0 auto;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;font-weight: normal;font-size: 14px;color:#565556;margin-top:10px;text-align: center;
		}
		p {
			color:#e4221c;font-size: 16px;text-align: center;margin-top:10px;
			span {
				margin-right: 3px;
			}
		}
	}
}
</style>
